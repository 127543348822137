<template>
  <div class="container ui-center">
    <!-- <h2>o(╥﹏╥)o</h2>
    <h1>404</h1>
    <p class="ui-c-dark fz14">页面找不到了 </p>
    <p> <a href="/">返回首页</a></p> -->
    <div class="warp">
      <img class="bg_w" src="@/assets/404_bgW.png" alt="">
      <!-- <img class="bg" src="@/assets/404_bg.png" alt=""> -->
      <h1>系统正在自动升级中<br />请稍后10分钟再试访问。</h1>
    </div>
    <copyright/>
  </div>
</template>

<script>
import Copyright from '@/components/Copyright'
export default {
  name: 'Page404',
  data() {
    return {
      error: '页面未找到'
    }
  },
  components: {
    Copyright,
  },
  created() {
    // if (process.env.NODE_ENV === 'production') {
    //   setTimeout(() => {
    //     window.location.replace('/')
    //   }, 3000)
    // }
  }
}
</script>
<style lang="less" scoped>
  .warp{
    background: #4863bf;
    padding-top: 50px;
    position: relative;
    height: 580px;
    margin-bottom: 10px;
    .bg_w{
      width: 300px;
    }
    .bg{
      width: 100%;
    }
    h1{
      width: 100%;
      font-size: 16px;
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 25px;
    }
  }
</style>

